<template>
  <div>
    <div class="headerDetail">
      <a-page-header title="需求工单详情" style="padding: 0" />
      <a-button type="primary" @click="goback">返回</a-button>
    </div>
    <a-collapse style="margin-top: 20px" v-model="activeKey">
      <a-collapse-panel key="1" header="工单信息">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item v-show="detailData.workNumber" label="工单编号">{{ detailData.workNumber }}</a-form-item>
          <a-form-item v-show="detailData.workStateName" label="工单状态">{{ detailData.workStateName }}</a-form-item>
          <a-form-item v-show="detailData.demandSource" label="需求来源">{{ detailData.demandSource == 1 ? '内部' : '用户' }}</a-form-item>
          <a-form-item v-show="detailData.demandTypeName" label="需求类型">{{ detailData.demandTypeName }}</a-form-item>
          <a-form-item v-show="detailData.startingTime" label="需求提出时间">{{ detailData.startingTime }}</a-form-item>
          <a-form-item v-show="detailData.projectName" label="项目信息">{{ detailData.projectName }}</a-form-item>
        </a-form>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="需求信息">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item v-show="detailData.demandDescribe" label="需求描述">{{ detailData.demandDescribe }}</a-form-item>
          <a-form-item label="附件" v-if="detailData.fileUrlList.length">
            <div class="annexBox">
              <div v-for="(item, index) in detailData.fileUrlList" class="imgClass" :key="index">
                <el-image class="annexBoxImage" v-if="ifPicture(item)" :src="item" :preview-src-list="previewImgList">
                </el-image>
                <div v-else class="videobox" @click="videoPlayer(item)">
                  <video class="videoClass" :src="item" type="video/mp4"></video>
                  <div class="mask">
                    <a-icon class="maskPlayer" type="play-circle" />
                  </div>
                </div>
              </div>
              <!-- <video muted="muted"  class="video" src="https://sqbj-public.oss-cn-beijing.aliyuncs.com/picture/0701aced08800000_d21f76a7e7272530c546dde67cee20c1.mp4" type="video/mp4" poster="" autoplay="autoplay" controls="controls" loop="-1">
                      <p>你的浏览器不支持video标签.</p>
               </video> -->
            </div>
          </a-form-item>
        </a-form>
      </a-collapse-panel>
      <a-collapse-panel v-show="detailData.enterpriseName||detailData.linkmanName" key="3" header="客户信息">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item v-show="detailData.enterpriseName" label="企业信息">{{ detailData.enterpriseName }}</a-form-item>
          <a-form-item v-show="detailData.linkmanName" label="客户姓名">{{ detailData.linkmanName }}</a-form-item>
          <a-form-item v-show="detailData.linkmanPhone" label="客户联系方式">{{ detailData.linkmanPhone }}</a-form-item>
          <a-form-item v-show="detailData.linkmanDutyName" label="客户职务">{{ detailData.linkmanDutyName }}</a-form-item>
        </a-form>
      </a-collapse-panel>
      <a-collapse-panel v-if="detailData.firstsClassifyName" key="4" header="归档信息">
        <a-form :form="detailData" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item v-show="detailData.firstsClassifyName" label="需求一级分类">{{ detailData.firstsClassifyName }}</a-form-item>
          <a-form-item v-show="detailData.secondsClassifyName" label="需求二级分类">{{ detailData.secondsClassifyName }}</a-form-item>
          <a-form-item v-show="detailData.demandServerTypeName" label="需求服务类型">{{ detailData.demandServerTypeName }}</a-form-item>
          <a-form-item v-show="detailData.isReasonableDemand!=null" label="是否有效需求">{{ detailData.isReasonableDemand == '1' ? '是' : '否' }}</a-form-item>
          <a-form-item v-show="detailData.solutionInformation" label="实际解决情况">{{
            detailData.solutionInformation == '1' ? '实际解决' : detailData.solutionInformation == '2'?'及时回复':'受限'
          }}</a-form-item>
          <a-form-item  v-show="detailData.unsolvableText" label="无法解决原因">{{
            detailData.unsolvableText
          }}</a-form-item>
        </a-form>
      </a-collapse-panel>
      <a-collapse-panel key="5" header="工单进程">
        <a-steps style="margin-left: 80px" progress-dot direction="vertical">
          <a-step status="finish" v-for="(item, index) in detailData.lifeCycle" :key="index">
            <template slot="title">
              {{ item.lifeCycleName }}
            </template>
            <div class="courseBox" slot="description">
              <div>操作人：{{ item.userName }}</div>
              <div>操作时间：{{ item.gmtModified }}</div>
              <div class="courseContent" ref="dataList">
                <div
                  v-if="
                    (item.lifeCycleName == '分派工单' && item.principalName) ||
                    (item.lifeCycleName == '提交工单' && item.principalName)
                  "
                >
                  <div v-show="item.principalName">
                    <div>服务负责人：{{ item.principalName }}</div>
                  </div>
                </div>

                <div v-if="item.lifeCycleName == '审核通过' && item.replyUserText">
                  <div class="displayFlex">
                    回复用户内容：<span class="flex1" v-html="handleDataEcho(item.replyUserText)"></span>
                  </div>
                </div>

                <div
                  v-if="
                    item.lifeCycleName != '提交工单' &&
                    item.lifeCycleName != '提交工单' &&
                    item.lifeCycleName != '取消回访' &&
                    item.lifeCycleName != '分派工单' &&
                    item.lifeCycleName != '工单归档' &&
                    item.lifeCycleName != '审核通过' &&
                    item.lifeCycleName != '选择问卷回访' &&
                    item.lifeCycleName != '接收工单'
                  "
                >
                  <div v-if="item.demandSource == '1'">
                    <div>
                      需求解决状态：{{
                        item.demandThawedState == '1'
                          ? '已解决'
                          : item.demandThawedState == '2'
                          ? '无法解决'
                          : item.demandThawedState == '3'
                          ? '延期解决'
                          : '关闭'
                      }}
                    </div>

                    <div v-show="item.demandThawedState == '1'">服务信息：{{ item.resolvedReason }}</div>
                    <div v-show="item.solutionTime">需求解决时间：{{ item.solutionTime }}</div>
                  </div>

                  <div v-if="item.demandSource == '2'">
                    <div v-show="item.serverDescribe">
                      <div>服务描述：{{ item.serverDescribe }}</div>
                    </div>
                    <div v-show="item.unsolvableText">
                      <div>无法解决原因：{{ item.unsolvableText }}</div>
                    </div>
                  </div>

                  <div class="displayFlex" v-if="item.serverDescribe && item.lifeCycleName != '结束工单'">
                    服务信息：<span class="flex1" v-html="handleDataEcho(item.serverDescribe)"></span>
                  </div>

                  <div v-if="item.replyUserText && item.lifeCycleName == '提交服务'">
                    <div class="displayFlex">
                      <span>解决方式：</span><span class="flex1" v-html="handleDataEcho(item.replyUserText)"></span>
                    </div>
                  </div>

                  <div v-if="item.replyUserText && item.lifeCycleName == '审核驳回'">
                    <div class="displayFlex">
                      驳回理由：<span class="flex1" v-html="handleDataEcho(item.replyUserText)"></span>
                    </div>
                  </div>

                  <div v-if="item.replyUserText && item.lifeCycleName == '提交审核'">
                    <div class="displayFlex">
                      解决方式：<span class="flex1" v-html="handleDataEcho(item.replyUserText)"></span>
                    </div>
                  </div>

                  <div v-if="item.replyUserText && item.lifeCycleName == '拒收工单'">
                    <div class="displayFlex">
                      拒绝理由：<span class="flex1" v-html="handleDataEcho(item.replyUserText)"></span>
                    </div>
                  </div>

                  <div v-if="item.unsolvableText && item.lifeCycleName != '结束工单'">
                    <div class="displayFlex">
                      无法解决原因：<span class="flex1" v-html="handleDataEcho(item.unsolvableText)"></span>
                    </div>
                  </div>
                  <div v-if="item.fileUrlList">
                    <div class="displayFlex" v-if="item.fileUrlList.length">
                    附件上传:

                    <div class="annexBox flex1">
                      <div v-for="(e, i) in item.fileUrlList" class="imgClass" :key="i">
                        <el-image
                          class="annexBoxImage"
                          v-if="ifPicture(e)"
                          :src="e"
                          :preview-src-list="item.fileUrlList"
                        >
                        </el-image>
                        <div v-else class="videobox" @click="videoPlayer(e)">
                          <!-- <video class="videoClass" :src="e" type="video/mp4"></video>
                          <div class="mask">
                            <a-icon class="maskPlayer" type="play-circle" />
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  

                  <div v-if="item.demandSource == '2' && item.replyUserText">
                    回复用户内容：{{ item.replyUserText }}
                  </div>

                  <div v-if="item.demandSource == '1' && item.resolvedReason && item.demandThawedState != '1'">
                    <div>
                      {{
                        item.demandThawedState == '2'
                          ? '无法解决原因'
                          : item.demandThawedState == '3'
                          ? '延期解决原因'
                          : item.demandThawedState == '4'
                          ? '关闭原因'
                          : ''
                      }}：{{ item.resolvedReason }}
                    </div>
                  </div>

                  <div v-if="item.cooperationName">
                    <div class="displayFlex">服务协作人：<span class="flex1" v-html="item.cooperationName"></span></div>
                  </div>

                  <div v-if="item.demandThawedState && item.unsolvableText">
                    <div class="displayFlex">
                      {{
                        item.demandThawedState == '2'
                          ? '无法解决原因'
                          : item.demandThawedState == '3'
                          ? '延期解决原因'
                          : item.demandThawedState == '4'
                          ? '关闭原因'
                          : ''
                      }}：<span
                        class="flex1"
                        v-show="
                          item.demandThawedState == '2' ||
                          item.demandThawedState == '3' ||
                          item.demandThawedState == '4'
                        "
                        v-html="handleDataEcho(item.unsolvableText)"
                      ></span>
                    </div>
                  </div>

                  <div v-if="item.isPromptResponse">
                    <div>
                      响应是否及时：{{ item.isPromptResponse == 1 ? '是' : item.isPromptResponse == 0 ? '否' : '' }}
                    </div>
                  </div>

                  <div v-if="item.isSatisfaction">
                    用户是否满意：{{
                      item.isSatisfaction == 1
                        ? '满意'
                        : item.isSatisfaction == 2
                        ? '一般'
                        : item.isSatisfaction == 3
                        ? '不满意'
                        : ''
                    }}
                  </div>

                  <div
                    class="displayFlex"
                    v-if="item.lifeCycleName == '选择电话回访' || item.lifeCycleName == '完成问卷回访'"
                  >
                    <span v-if="item.returnVisitText">
                      其他反馈内容：<span class="flex1" v-html="handleDataEcho(item.returnVisitText)"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a-step>
        </a-steps>
      </a-collapse-panel>
    </a-collapse>
    <a-modal :visible="videoVisible" :footer="null" @cancel="closeVideoPreview">
      <video :src="videoUrl" width="100%" height="600px;" controls="controls" id="video">您的浏览器不支持播放该视频！</video>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
import { requirementPooldetail } from '@/api/demandPoolShunyi';
import { handleData } from '../index';
export default {
  data() {
    return {
      detailData: {}, //详情数据
      videoVisible: false, //视频播放弹窗
      videoUrl: '', //预览视频地址
      activeKey: ['1', '2', '3', '4', '5'], //折叠面板展开数据
      previewImgList: [],
      // imgList: ['https://oss-workplace-prod.innoecos.cn/picture/06c3f86c7fc00000_c836f6d5d8f5a9683e99cb769290ea55.gif',
      //     'https://oss-workplace-prod.innoecos.cn/picture/06834ae9c7800000_%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20230418095405.png',
      //     'https://oss-workplace-prod.innoecos.cn/picture/05ff360664400000_%E5%88%86%E4%BA%AB%E5%9B%BE.png',
      //     'https://sqbj-public.oss-cn-beijing.aliyuncs.com/picture/0701b2ea2c400000_%E9%A3%9E%E4%B9%A620230725-145734.mp4'
      // ]
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const res = await requirementPooldetail(this.$route.query.id);
      if (res.code == 200) {
        //判断是否是图片格式
        res.data.fileUrlList.forEach((element) => {
          if (this.ifPicture(element)) {
            this.previewImgList.push(element);
          }
        });
        this.detailData = res.data;
        //工单进程当中某个节点只有操作人和操作时间的时候  隐藏内容模块
        this.$nextTick(() => {
          let list = this.$refs.dataList;
          list.forEach((item) => {
            if (!item.innerText) {
              item.style.display = 'none';
            }
          });
        });
      }
    },
    //播放视频
    videoPlayer(url) {
      this.videoUrl = url;
      this.videoVisible = true;
    },
    //关闭视频
    closeVideoPreview() {
      this.videoUrl = '';
      this.videoVisible = false;
    },
    //判断是否是图片
    ifPicture(url) {
      console.log(url)
      let type = true;
      let index = url.lastIndexOf('.');
      let result = url.slice(index + 1);
      if (result == 'mp4'||result == 'MOV') {
        type = false;
      }
      return type;
    },
    //处理进程回显
    handleDataEcho(value) {
      let val = handleData(value);
      return val;
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.annexBox {
  display: flex;
}

.imgClass {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.annexBoxImage {
  width: 100px;
  height: 100px;
}

.videoClass {
  width: 100px;
  height: 100px;
}

.videobox {
  position: relative;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maskPlayer {
  color: #fff;
  font-size: 25px;
}

.videoPreview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoPreviewClass {
  width: 50%;
}

.videoPreviewClose {
  position: fixed;
  top: 12%;
  right: 15%;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.courseContent {
  background: #fafafa;
  padding: 7px 5px;
  margin-top: 5px;
}

.courseBox {
  width: 100%;
}

::v-deep .ant-steps-dot .ant-steps-item-content {
  width: calc(100% - 100px);
}

.displayFlex {
  display: flex;
}

.flex1 {
  flex: 1;
}

::v-deep p {
  margin-bottom: 0;
}
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 16px;
}
</style>


